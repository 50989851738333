<template>
  <RoleForm mode="Ubah" module="Role"> </RoleForm>
</template>

<script>
import RoleForm from './form';

const RoleUpdate = {
  name: 'RoleUpdate',
  components: { RoleForm },
};

export default RoleUpdate;
</script>
